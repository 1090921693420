exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chicken-js": () => import("./../../../src/pages/chicken.js" /* webpackChunkName: "component---src-pages-chicken-js" */),
  "component---src-pages-ham-js": () => import("./../../../src/pages/ham.js" /* webpackChunkName: "component---src-pages-ham-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspirations-js": () => import("./../../../src/pages/inspirations.js" /* webpackChunkName: "component---src-pages-inspirations-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-turkey-js": () => import("./../../../src/pages/turkey.js" /* webpackChunkName: "component---src-pages-turkey-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-blog-post-js": () => import("./../../../src/templates/articleBlogPost.js" /* webpackChunkName: "component---src-templates-article-blog-post-js" */),
  "component---src-templates-chicken-js": () => import("./../../../src/templates/chicken.js" /* webpackChunkName: "component---src-templates-chicken-js" */),
  "component---src-templates-ham-js": () => import("./../../../src/templates/ham.js" /* webpackChunkName: "component---src-templates-ham-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-turkey-js": () => import("./../../../src/templates/turkey.js" /* webpackChunkName: "component---src-templates-turkey-js" */),
  "component---src-templates-video-blog-post-js": () => import("./../../../src/templates/videoBlogPost.js" /* webpackChunkName: "component---src-templates-video-blog-post-js" */)
}

